/**
 * 组件: 私有路由
 * 功能: 控制路由是否可直接访问
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import userInfo from "@src/utils/userInfo";
export default function PrivateRoute(route) {
  console.log("权限路由->",route);
  const {
    user: { token },
  } = userInfo;
  return token ? (
    <Route exact path={route.path} render={(props) => route.render(props)}/>
  ) : (
    <Redirect to="/login" />
  );
}

