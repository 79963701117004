// 公共方法库
export function getCurrentURL() {
  return window.location.pathname;
}

export function getkeyboardHeight(element) {
  let keyboardHeight = 0;
  let screenHeight = window.innerHeight;
  element.addEventListener("focus", function (evt) {
    if (!keyboardHeight) {
      let timer = setInterval(function () {
        if (screenHeight !== window.innerHeight) {
          keyboardHeight = screenHeight - window.innerHeight;
          clearInterval(timer);
        }
      }, 50);
    }
  });

  return keyboardHeight;
}

export function serialize(formElement) {
  console.log(formElement, "formElement");
  var res = [], //存放结果的数组
    current = null, //当前循环内的表单控件
    i, //表单NodeList的索引
    len, //表单NodeList的长度
    k, //select遍历索引
    optionLen, //select遍历索引
    option, //select循环体内option
    optionValue, //select的value
    form = formElement; //用form变量拿到当前的表单，易于辨识

  for (i = 0, len = form.elements.length; i < len; i++) {
    current = form.elements[i];

    //disabled表示字段禁用，需要区分与readonly的区别
    if (current.disabled) continue;

    switch (current.type) {
      //可忽略控件处理
      case "file": //文件输入类型
      case "submit": //提交按钮
      case "button": //一般按钮
      case "image": //图像形式的提交按钮
      case "reset": //重置按钮
      case undefined: //未定义
        break;
      //select控件
      case "select-one":
      case "select-multiple":
        if (current.name && current.name.length) {
          console.log(current);
          for (k = 0, optionLen = current.options.length; k < optionLen; k++) {
            option = current.options[k];
            optionValue = "";
            if (option.selected) {
              if (option.hasAttribute) {
                optionValue = option.hasAttribute("value")
                  ? option.value
                  : option.text;
              } else {
                //低版本IE需要使用特性 的specified属性，检测是否已规定某个属性
                optionValue = option.attributes("value").specified
                  ? option.value
                  : option.text;
              }
            }
            res.push(
              encodeURIComponent(current.name) +
                "=" +
                encodeURIComponent(optionValue)
            );
          }
        }
        break;

      //单选，复选框
      case "radio":
      case "checkbox":
        //这里有个取巧 的写法，这里的判断是跟下面的default相互对应。
        //如果放在其他地方，则需要额外的判断取值
        if (!current.checked) break;
        break;
      default:
        //一般表单控件处理
        if (current.name && current.name.length) {
          res.push(
            encodeURIComponent(current.name) +
              "=" +
              encodeURIComponent(current.value)
          );
        }
    }
  }
  return res.join("&");
}
// 兼容部分ios手机input失焦后页面上移问题
export function iosKeyboardUp() {
  // console.log("调用了iosKeyboardUp方法");
  let myFunction;
  let isWXAndIos = isWeiXinAndIos();
  if (isWXAndIos) {
    // 既是微信浏览器 又是ios============（因为查到只有在微信环境下，ios手机上才会出现input失去焦点的时候页面被顶起）
    document.body.addEventListener("focusin", () => {
      // 软键盘弹起事件
      clearTimeout(myFunction);
    });
    document.body.addEventListener("focusout", () => {
      // 软键盘关闭事件
      clearTimeout(myFunction);
      myFunction = setTimeout(function () {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // 重点  =======当键盘收起的时候让页面回到原始位置
      }, 200);
    });
  }
}

export function isWeiXinAndIos() {
  // console.log("调用了isWeiXinAndIos方法");

  // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  let ua = "" + window.navigator.userAgent.toLowerCase();
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串且是IOS系统
  let isWeixin = /MicroMessenger/i.test(ua); // 是在微信浏览器
  let isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua); // 是IOS系统
  return isWeixin && isIos;
}
