// 路由配置
import { lazy } from "react";
let Search = lazy(() => import("@page/Search"));
let ViewpointDetails = lazy(() => import("@page/ViewpointDetails"));
let ConsultingDetails = lazy(() => import("@page/ConsultingDetails"));
let Consulting = lazy(() => import("@page/Consulting"));
let GaoJingjianJoin = lazy(() => import("@page/GaoJingjianJoin"));
let FundInstitutionJoin = lazy(() => import("@page/FundInstitutionJoin"));
let JoinService = lazy(() => import("@page/JoinService"));
let TownInquiryForm = lazy(() => import("@page/TownInquiryForm"));
let TownApplyForm = lazy(() => import("@page/TownApplyForm"));
let TownTalentApplyForm=lazy(()=>import("@page/TownTalentApplyForm"));
let TownTalentList=lazy(()=>import("@page/TownTalentList"));
let TownService = lazy(() => import("@page/TownService"));
let Other = lazy(() => import("@page/Other"));
let SignUpForm = lazy(() => import("@page/SignUpForm"));
let LiveDetails = lazy(() => import("@page/LiveDetails"));
let AppLiveDetails = lazy(() => import("@page/AppLiveDetails"));
let SpecialDetails = lazy(() => import("@page/SpecialDetails"));
let GuestDetails = lazy(() => import("@page/GuestDetails"));
let MasterDetails = lazy(() => import("@page/MasterDetails"));
let Master = lazy(() => import("@page/Master"));
let Special = lazy(() => import("@page/Special"));
let Guest = lazy(() => import("@page/Guest"));
let CourseList = lazy(() => import("@page/CourseList"));
// let CourseL = lazy(() => import("@page/CourseL"));
let Details = lazy(() => import("@page/Details"));
let Viewpoint = lazy(() => import("@page/Viewpoint"));
let ActivityDetails = lazy(() => import("@page/ActivityDetails"));
let Activity = lazy(() => import("@page/Activity"));
let Live = lazy(() => import("@page/Live"));
let RoadShow = lazy(() => import("@page/RoadShow"));
let RoadShowDetails = lazy(() => import("@page/RoadShow/RoadShowDetails"));
let RoadShowBack = lazy(() => import("@page/RoadShow/RoadShowBack"));
let RoadShowLive = lazy(() => import("@page/RoadShow/RoadShowLive"));
let SolicitationDetails = lazy(() =>
  import("@page/RoadShow/SolicitationDetails")
);
let financingPage = lazy(() => import("@page/Financing"));
let Project = lazy(() => import("@page/Project"));
let ProjectDetails = lazy(() => import("@page/ProjectDetails"));
let SessionDetails = lazy(() => import("@page/SessionDetails"));
let Course = lazy(() => import("@page/Course"));
let Home = lazy(() => import("@page/Home"));
let Login = lazy(() => import("@page/Login"));
let BindingMobile = lazy(() => import("@page/Login/BindingMobile"));
let MyApplication = lazy(() => import("@page/MyApplication"));
let MyConsultation = lazy(() => import("@page/MyConsultation"));
let MyAttention = lazy(() => import("@page/MyAttention"));
let Personal = lazy(() => import("@page/Personal"));
let MyCollection = lazy(() => import("@page/MyCollection"));
let MySignup = lazy(() => import("@page/MySignup"));
let Contactus = lazy(() => import("@page/Contactus"));
let PrivacyPolicy = lazy(() => import("@page/PrivacyPolicy"));
let UserAgreement = lazy(() => import("@page/UserAgreement"));
let ApplyDetails = lazy(() => import("@page/ApplyDetails"));
let TansferStation = lazy(() => import("@page/TansferStation"));
let IdentityPage = lazy(() => import("@page/Login/IdentityPage"));
let ServiceVideo = lazy(() => import("@page/ServiceVideo"));
let ServiceVideoList = lazy(() => import("@page/ServiceVideoList"));
let AggregatePage = lazy(() => import("@page/AggregatePage"));

const Route = [
  {
    path: "/",
    Component: Home,
    title: "云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/aggregatePage",
    Component: AggregatePage,
    title: "云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/tansferStation",
    Component: TansferStation,
    title: "云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/personal",
    Component: Personal,
    title: "我的-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/login",
    Component: Login,
    title: "登录-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/identityPage",
    Component: IdentityPage,
    title: "身份选择-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/binding",
    Component: BindingMobile,
    title: "绑定手机号-云端 · 北京基金小镇",
    exact: false,
    isPrivate: false,
  },
  {
    path: "/course",
    Component: Course,
    title: "课程-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/course/:id",
    Component: Details,
    title: "课程-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/courseList",
    Component: CourseList,
    title: "课程-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  // {
  //   path: "/courseL",
  //   Component: CourseL,
  //   title: "课程-云端 · 北京基金小镇",
  //   exact: true,
  //   isPrivate: false,
  // },
  {
    path: "/financing",
    Component: financingPage,
    title: "投融平台-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/project",
    Component: Project,
    title: "项目-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/project/:id",
    Component: ProjectDetails,
    title: "项目-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path:'/session/:id',
    Component: SessionDetails,
    title: "合作方-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/roadShow",
    Component: RoadShow,
    title: "路演-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/roadShow/:id",
    Component: RoadShowDetails,
    title: "路演-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/roadShowlookback/:id",
    Component: RoadShowBack,
    title: "路演回看-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/roadShowlive/:id",
    Component: RoadShowLive,
    title: "路演直播-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/solicitation/:id",
    Component: SolicitationDetails,
    title: "征集-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/live",
    Component: Live,
    title: "直播-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/live/:id",
    Component: LiveDetails,
    title: "直播-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/live/app/:id/:phone",
    Component: AppLiveDetails,
    title: "直播-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/activity",
    Component: Activity,
    title: "活动-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/activity/:id",
    Component: ActivityDetails,
    title: "活动-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/viewpoint",
    Component: Viewpoint,
    title: "观点-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/viewpoint/:id",
    Component: ViewpointDetails,
    title: "观点-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/guest",
    Component: Guest,
    title: "大咖-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/guest/:id",
    Component: GuestDetails,
    title: "大咖-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/special",
    Component: Special,
    title: "专题-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/special/:id",
    Component: SpecialDetails,
    title: "专题-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/master",
    Component: Master,
    title: "导师-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/master/:id",
    Component: MasterDetails,
    title: "导师-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/singUpForm/:id/:inner/:type/:backPath",
    Component: SignUpForm,
    title: "报名-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/townservice",
    Component: TownService,
    title: "服务-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/townApplyForm",
    Component: TownApplyForm,
    title: "申请-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/townTalentApplyForm",
    Component:TownTalentApplyForm,
    title:"人才服务申请-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false
  },
  {
    path:'/townTalentList',
    Component:TownTalentList,
    title: "人才通知-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false
  },
  {
    path: "/townInquiryForm",
    Component: TownInquiryForm,
    title: "咨询-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/joinservice",
    Component: JoinService,
    title: "入驻-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/fundInstitutionJoin",
    Component: FundInstitutionJoin,
    title: "入驻-北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/gaoJingjianJoin",
    Component: GaoJingjianJoin,
    title: "入驻-北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/servicevideo/:id",
    Component: ServiceVideo,
    title: "入驻-北京基金小镇",
    exact: false,
    isPrivate: false,
  },
  {
    path: "/servicevideoList",
    Component: ServiceVideoList,
    title: "入驻-北京基金小镇",
    exact: true,
    isPrivate: false,
  },

  {
    path: "/consulting",
    Component: Consulting,
    title: "咨询-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/consulting/:id",
    Component: ConsultingDetails,
    title: "咨询详情-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/search",
    Component: Search,
    title: "搜索-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/other",
    Component: Other,
    title: "搜索-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/myApplication",
    Component: MyApplication,
    title: "我的申请-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/applyDetails/:id",
    Component: ApplyDetails,
    title: "申请-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/myConsultation/:id",
    Component: MyConsultation,
    title: "我的咨询-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/myAttention",
    Component: MyAttention,
    title: "我的关注-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/myCollection",
    Component: MyCollection,
    title: "我的收藏-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/mySignup",
    Component: MySignup,
    title: "我的报名-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/contactus",
    Component: Contactus,
    title: "联系我们-云端 · 北京基金小镇",
    exact: true,
    isPrivate: true,
  },
  {
    path: "/userAgreement",
    Component: UserAgreement,
    title: "用户协议-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
  {
    path: "/privacyPolicy",
    Component: PrivacyPolicy,
    title: "隐私政策-云端 · 北京基金小镇",
    exact: true,
    isPrivate: false,
  },
];

export default Route;
