/**
 * 组件 根组件
 */
import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "@src/components/PrivateRoute";
import storage from "@src/utils/storage";
import routerHistory from "@src/utils/routerHistory";
import { iosKeyboardUp } from "@src/utils/utils";
import "./App.less";
import RouteList from "./router";
iosKeyboardUp();

let prePath = '/';

class App extends Component {



  // 设置标签栏title
  setTitle = (title) => {
    // console.log("调用了", title);
    document.title = title;
  };
  // 存储除了登录每次的访问地址
  setFormPath = (path) => {
    if (
      path !== "/login" &&
      path !== "/binding" &&
      path !== "/personal" &&
      path !== "/tansferStation" &&
      path !== "/identityPage"&&
      path.indexOf("/solicitation")===-1
    ) {
      storage.setFromPath(path);
      // alert("调用了路由存储")
      routerHistory.fromPath = path;
    }
  };

  componentDidCatch(error, info) {
    console.log("Error::::", error);
    console.log("info", info);
    if (String(error).includes("Loading chunk")||String(error).includes("Loading CSS chunk")) {
      window.location.reload();
    }
  };

  componentDidMount() {
    prePath = window.location.href;
  }


  umengSend(props) {
    if (window._czc) {
      let location = window.location;
      let contentUrl = location.pathname + location.hash;
      window._czc.push(["_trackPageview", contentUrl, prePath]);
      prePath = props.location.pathname
      //友盟统计数据，单页面应用需要监听路由跳转
      const hm=document.createElement('script');
      hm.src="https://s4.cnzz.com/z_stat.php?id=1280170972&web_id=1280170972";
      const s=document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm,s)
    }
  }
  render() {
    const { setTitle, setFormPath } = this;
    return (
      <BrowserRouter>
        <Switch>
          {RouteList.map((route, index) => {
            const { path, Component, title, exact, isPrivate } = route;
            // console.log(isPrivate,route,Component);
            return isPrivate === true ? (
              <PrivateRoute
                key={index}
                exact={exact}
                path={`${path}`}
                render={(props) => {
                  setTitle(title);
                  setFormPath(props.location.pathname);
                  this.umengSend(props);
                  return (
                    <Suspense fallback={<Loading />}>
                      <Component {...props} />
                    </Suspense>
                  );
                }}
              ></PrivateRoute>
            ) : (
              <Route
                key={index}
                exact={exact}
                path={`${path}`}
                render={(props) => {
                  setTitle(title);
                  setFormPath(props.location.pathname);
                  this.umengSend(props);
                  return (
                    <Suspense fallback={<Loading />}>
                      <Component {...props} />
                    </Suspense>
                  );
                }}
              ></Route>
            );
          })}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-text">数据加载中</div>
    </div>
  );
};
export default App;

