/**
 * 入口文件
 */
import React from "react";
import ReactDOM from "react-dom";
import "./static/css/css-reset.less";
import App from "./App.jsx";
import "lib-flexible";
import storage from "@src/utils/storage";
import userInfo from "@src/utils/userInfo";
import "./index.less";

// 读取本地user信息
const user = storage.getUser();
userInfo.user = user;
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
