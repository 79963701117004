// 本地存储
import storage from "store";
const USER_STORAGE = "user_storage";
const USER_FROMPATH = "user_frompath";
const storageObject = {
  // 用户token用户信息
  setUser(user) {
    storage.set(USER_STORAGE, user);
  },
  // 取用户token用户信息
  getUser() {
    return storage.get(USER_STORAGE) || {};
  },
  // 清空
  removeUser() {
    storage.remove(USER_STORAGE);
  },
  // 存路由
  setFromPath(path) {
    storage.set(USER_FROMPATH, path);
  },
  // 取路由
  getFromPath() {
    return storage.get(USER_FROMPATH) || "/";
  },
  removeFromPath() {
    storage.remove(USER_FROMPATH);
  },
};
export default storageObject;
